import {
  Subscription,
  SubscriptionType,
} from "types/entities/subscriptionTypes"
import { DrugType } from "types/entities/drugTypes"
import SemaglutideImage from "assets/images/medications/semaglutide.png"
import TirzepatideImage from "assets/images/medications/tirzepatide.png"

export const getFrequency = (subscription: Subscription) => {
  switch (subscription.billing_period || "monthly") {
    case "custom":
      return `Every ${subscription.billingcustom} days`
    case "monthly":
      return "Every month"
    case "quarterly":
      return "Every quarter"
    case "6-month":
      return "Every 6 months"
    case "yearly":
      return "Every year"
  }
}
export const replaceTypeInText = (text: string, type: SubscriptionType) => {
  const typeText = getTypeName(type)

  return text.replace("{type}", typeText)
}

export const getTypeName = (type: SubscriptionType) => {
  return type === SubscriptionType.SUBSCRIPTION ? "subscription" : "membership"
}

export const getDrugImage = (drugName: string | undefined) => {
  if (drugName === DrugType.SEMAGLUTIDE) {
    return SemaglutideImage
  }
  if (drugName === DrugType.TIRZEPATIDE) {
    return TirzepatideImage
  }
  return null
}
