import { api } from "app/services/api"
import {
  Subscription,
  SubscriptionReferralLink,
} from "types/entities/subscriptionTypes"
import { CheckoutData } from "features/checkout/CheckoutSteps"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import { formatDateToYYYYMMDD } from "utils/dateTimeUtils"

export const subscriptionApi = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getSubscription: build.query<Subscription, string>({
        query: (slug) => `subscriptions-by-slug/${slug}`,
      }),
      getSubscriptionReferralLink: build.query<
        SubscriptionReferralLink,
        string
      >({
        query: (slug) => `subscription-referral-link-by-slug/${slug}`,
      }),
      checkoutSubscription: build.mutation<
        UserSubscription,
        { checkoutData: CheckoutData }
      >({
        query: ({ checkoutData }) => {
          // Convert dateOfBirth to ISO string if it exists
          if (checkoutData.accountInfo.dateOfBirth) {
            checkoutData.accountInfo.dateOfBirth = formatDateToYYYYMMDD(
              new Date(checkoutData.accountInfo.dateOfBirth),
            )
          }

          return {
            url: `/checkout`,
            method: "PUT",
            body: JSON.stringify(checkoutData),
            headers: {
              "content-type": "application/ld+json",
            },
          }
        },
        invalidatesTags: ["Account"],
      }),
    }
  },
})

export const {
  useGetSubscriptionQuery,
  useLazyGetSubscriptionQuery,
  useGetSubscriptionReferralLinkQuery,
  useLazyGetSubscriptionReferralLinkQuery,
  useCheckoutSubscriptionMutation,
} = subscriptionApi
