import { ApiError } from "types/core/apiTypes"
import {
  useLazyGetSubscriptionQuery,
  useLazyGetSubscriptionReferralLinkQuery,
} from "app/services/subscriptionApi"
import { useEffect, useState } from "react"
import {
  Subscription,
  SubscriptionReferralLink,
} from "types/entities/subscriptionTypes"

const useGetSubscriptionReferralLinkBySlug = (slug: string) => {
  const [getSubscription] = useLazyGetSubscriptionQuery()
  const [getReferralLink] = useLazyGetSubscriptionReferralLinkQuery()
  const [subscription, setSubscription] = useState<Subscription>()
  const [referralLink, setReferralLink] = useState<SubscriptionReferralLink>()
  const [invalid, setInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetch = async () => {
    setIsLoading(true)
    try {
      const result = await getReferralLink(slug).unwrap()
      setReferralLink(result)
      setSubscription(result.subscription)
    } catch (error) {
      if ((error as ApiError).status === 404) {
        try {
          const result = await getSubscription(slug).unwrap()
          setSubscription(result)
        } catch (error) {
          setInvalid(true)
        }
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [])

  return {
    subscription,
    referralLink,
    invalid,
    isLoading,
  }
}

export default useGetSubscriptionReferralLinkBySlug
