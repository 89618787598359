import { entityId } from "types/core/basicEntityTypes"
import { Drug } from "types/entities/drugTypes"
import { Physician } from "types/entities/physicianTypes"

export interface Subscription extends entityId {
  billing_cost: number
  billing_period: string
  title: string
  description: string
  billingcustom: number
  sub_type: SubscriptionType
  drug: Drug
  sub_interval: number
  physician: Physician
}

export enum SubscriptionType {
  SUBSCRIPTION = "package",
  MEMBERSHIP = "membership",
}

export interface SubscriptionReferralLink extends entityId {
  subscription: Subscription
}
